import React from 'react'
import image from "../../Assets/Images/Desktop/planoEstadio_primer_dia@-cropped.svg"
import "./Itinerary.scss"
import { useTranslation } from "react-i18next"

function Itinerary() {
    const [t] = useTranslation("global")
    return (
        <div className="itinerary">
            <div className="itinerary-tittle">
                <h1>{t("itinerary.tittle")}</h1>
            </div>

            <div className="itinerary-container">
                <div className="itinerary-img">
                    <img src={image} alt="estadio" />
                </div>

                <div className="itinerary-text">
                    <div className="itinerary-text-container">
                        <div className="itinerary-text-individual">
                            <div className="itinerary-text-individual-number">
                                <p>1</p>
                            </div>
                            <p className="itinerary-text-individual-text">{t("itinerary.1")}</p>
                        </div>
                        <div className="itinerary-text-individual">
                            <div className="itinerary-text-individual-number">
                                <p>2</p>
                            </div>
                            <p className="itinerary-text-individual-text">{t("itinerary.2")}</p>
                        </div>
                        <div className="itinerary-text-individual">
                            <div className="itinerary-text-individual-number">
                                <p>3</p>
                            </div>
                            <p className="itinerary-text-individual-text">{t("itinerary.3")}</p>
                        </div>
                        <div className="itinerary-text-individual">
                            <div className="itinerary-text-individual-number">
                                <p>4</p>
                            </div>
                            <p className="itinerary-text-individual-text">{t("itinerary.4")}</p>
                        </div>
                        <div className="itinerary-text-individual">
                            <div className="itinerary-text-individual-number">
                                <p>5</p>
                            </div>
                            <p className="itinerary-text-individual-text">{t("itinerary.5")}</p>
                        </div>
                        <div className="itinerary-text-individual">
                            <div className="itinerary-text-individual-number">
                                <p>6</p>
                            </div>
                            <p className="itinerary-text-individual-text">{t("itinerary.6")}</p>
                        </div>
                        <div className="itinerary-text-individual">
                            <div className="itinerary-text-individual-number">
                                <p>7</p>
                            </div>
                            <p className="itinerary-text-individual-text">{t("itinerary.7")}</p>
                        </div>
                        <div className="itinerary-text-individual">
                            <div className="itinerary-text-individual-number">
                                <p>8</p>
                            </div>
                            <p className="itinerary-text-individual-text">{t("itinerary.8")}</p>
                        </div>
                        <div className="itinerary-text-individual">
                            <div className="itinerary-text-individual-number">
                                <p>9</p>
                            </div>
                            <p className="itinerary-text-individual-text">{t("itinerary.9")}</p>
                        </div>
                        <div className="itinerary-text-individual">
                            <div className="itinerary-text-individual-number">
                                <p>10</p>
                            </div>
                            <p className="itinerary-text-individual-text">{t("itinerary.10")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Itinerary