import React from 'react'
import "./Home.scss"
import { useTranslation } from "react-i18next"
import Navbar from "./../Navbar/Navbar"
import videoDesk from "../../Assets/Videos/new-video-real.mp4"
import videoMob from "../../Assets/Videos/VIDEO VERTICAL_low.mp4"

export default function Home() {
    const [t] = useTranslation("global")

    return (
        <div>
            <div className="home">
                <Navbar />
                <video autoPlay playsInline loop muted className="videodesk"><source src={videoDesk} type="video/mp4" /></video>
                <video autoPlay playsInline loop muted className="videomob"><source src={videoMob} type="video/mp4" /></video>
                <h1>{t("home.tour")}</h1>
                <h2>{t("home.arena")}</h2>
                <h3>{t("home.visitUs")}</h3>
                <button onClick={() =>
                        window.open(
                        "https://bstadium.es/tours-originales/tour-interactivo-reale-arena-estadio-real-sociedad/"
                        )
                    }>{t("home.button")}</button>
            </div>
        </div>
    )
}
