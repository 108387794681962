import React from "react";
import "./Contact.scss";
import "../Information/Information.scss";
import escudo from "../../Assets/Images/Desktop/real-sociedad-logo.svg";
import bstadium from "../../Assets/Images/Desktop/Powered-Bstadium.svg";
import calendar from "../../Assets/Images/Desktop/CalendarSVG.svg";
import clock from "../../Assets/Images/Desktop/ClockSVG.svg";
import location from "../../Assets/Images/Desktop/LocationSVG.svg";
import menor from "../../Assets/Images/Desktop/MenorSVG.svg";
import language from "../../Assets/Images/Desktop/LanguageSVG.svg";
import declined from "../../Assets/Images/Desktop/DeclinedSVG.svg";
import custom from "../../Assets/Images/Desktop/PersonalizarSVG.svg";
import Itinerary from "../Itinerary/Itinerary";
import { useTranslation } from "react-i18next"

export default function Contact() {
  const [t] = useTranslation("global")

  return (
    <div class="footer-container">

      <div className="informacion-container">
        <Itinerary />
        <div className="informacion-title">
          <h1>{t("information.tittle")}</h1>
        </div>
        <div className="informacion-text">
          <div className="informacion-text-calendar">
            <img src={calendar} alt="calendario" />
            <p className="calendar-b">
              <b >{t("information.date")} </b>
            </p>
            <div className="p-text">
              <p>
                {t("information.date1")} <b>{t("information.dateb")}</b> <p>{t("information.date2")} <b>{t("information.dateb2")}</b></p> <p>{t("information.date3")} <b>{t("information.dateb3")}</b></p><p>{t("information.date4")}</p>
              </p>
            </div>
          </div>
        </div>
        <div className="informacion-text">
          <div className="informacion-text-clock">
            <img src={clock} alt="clock" />
            <p>
              <b>{t("information.duration")} </b>
            </p>
            <div className="p-text">
              <p>{t("information.duration-text")}</p>
            </div>
          </div>
        </div>
        <div className="informacion-text">
          <div className="informacion-text-location">
            <img src={location} alt="location" />
            <p>
              <b>{t("information.place")} </b>
            </p>
            <div className="p-text">
              <p>{t("information.place-text")}</p>
            </div>
          </div>
        </div>
        <div className="informacion-text">
          <div className="informacion-text-menor">
            <img src={menor} alt="menor" />
            <p>
              <b>{t("information.age")} </b>
            </p>
            <div className="p-text">
              <p>{t("information.age-text")}</p>
            </div>
          </div>
        </div>
        <div className="informacion-text">
          <div className="informacion-text-language">
            <img src={language} alt="language" />
            <p>
              <b>{t("information.language")} </b>
            </p>
            <div className="p-text">
              <p>{t("information.language-text")}</p>
            </div>
          </div>
        </div>
        <div className="informacion-text">
          <div className="informacion-text-declined">
            <img src={declined} alt="declined" />
            <p>
              <b>{t("information.declined")} </b>
            </p>
            <div className="p-text">
              <p>{t("information.declined-text")}</p>
            </div>
          </div>
        </div>
        <div className="informacion-text">
          <div className="informacion-text-custom">
            <img src={custom} alt="language" />
            <p>
              <b>{t("information.custom")} </b>
            </p>
            <div className="p-text">
              <p>{t("information.custom-text")}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="informacion-container-mobile">

        <div className="informacion-container-mobile-general">
          <div className="mobile-general-icon-calendar">
            <img src={calendar} alt="calendario" />
          </div>
          <div className="mobile-general-text">
            <p>
              <b>{t("information.date")}</b>
              <p id="id">{t("information.date1")} <b>{t("information.dateb")}</b></p>
              <p id="id">{t("information.date2")} <b>{t("information.dateb2")}</b></p>
              <p id="id">{t("information.date3")} <b>{t("information.dateb3")}</b></p>
              <p id="id">{t("information.date4")}</p>
            </p>
          </div>
        </div>
        <div className="informacion-container-mobile-general">
          <div className="mobile-general-icon-clock">
            <img src={clock} alt="calendario" />
          </div>
          <div className="mobile-general-text">
            <p><b>{t("information.duration")}</b> {t("information.duration-text")}</p>
          </div>
        </div>
        <div className="informacion-container-mobile-general">
          <div className="mobile-general-icon-location">
            <img src={location} alt="calendario" />
          </div>
          <div className="mobile-general-text">
            <p className="informacion-p-location"><b>{t("information.place")}</b> {t("information.place-text")}</p>
          </div>
        </div>
        <div className="informacion-container-mobile-general">
          <div className="mobile-general-icon-menor">
            <img src={menor} alt="calendario" />
          </div>
          <div className="mobile-general-text">
            <p id="textoId"><b>{t("information.age")}</b> {t("information.age-text")}</p>
          </div>
        </div>
        <div className="informacion-container-mobile-general">
          <div className="mobile-general-icon-language">
            <img src={language} alt="calendario" />
          </div>
          <div className="mobile-general-text">
            <p><b>{t("information.language")}</b> {t("information.language-text")}</p>
          </div>
        </div>
        <div className="informacion-container-mobile-general">
          <div className="mobile-general-icon-declined">
            <img src={declined} alt="calendario" />
          </div>
          <div className="mobile-general-text">
            <p><b>{t("information.declined")}</b>{t("information.declined-text")}</p>
          </div>
        </div>
        <div className="informacion-container-mobile-general">
          <div className="mobile-general-icon-custom">
            <img src={custom} alt="calendario" />
          </div>
          <div className="mobile-general-text">
            <p><b>{t("information.custom")}</b> {t("information.custom-text")}</p>
          </div>
        </div>

      </div>

      {/* INFORMATION CONTAINER FIN */}

      <div className="contacto">
        <h1>{t("contact.tittle")}</h1>
        <p>
          <b>{t("contact.contact")}</b>
        </p>
        <p>{t("contact.number")}</p>
        <p>{t("contact.email")}</p>
      </div>
      <div className="disfruta">
        <h1>
          {t("contact.reale")} <b>{t("contact.arena")}</b>
        </h1>
        <button id="entradas" onClick={() =>
                        window.open(
                        "https://bstadium.es/tours-originales/tour-interactivo-reale-arena-estadio-real-sociedad/"
                        )
                    }>{t("contact.ticket")}</button>
        <img src={escudo} alt="escudo" />
        <div className="disfruta-bstadium">
          <p>Powered by</p>
          <img src={bstadium} alt="escudo" />
        </div>
      </div>
    </div>
  );
}